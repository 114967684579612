import { BrowserCacheLocation } from '@azure/msal-browser';
import { portal } from '../../environment/environment.configs';
import { IAuthConfig } from '../auth.types';
import { loggerCallback } from '../logger/logger';

export const ibmec: IAuthConfig[] = [
  {
    envs: ['dev', 'local'],
    brands: ['ibmec'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: 'cdb8e5e5-8afc-4784-9194-ddfda450c047',
          authority: 'https://login.dev.ibmec.br/ibmecdev.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.dev.ibmec.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://ibmecdev.onmicrosoft.com/cdb8e5e5-8afc-4784-9194-ddfda450c047/user.read',
      },
    },
  },
  {
    envs: ['qld'],
    brands: ['ibmec'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '201b732f-6414-49f9-bf61-721e90f0a7cd',
          authority: 'https://login.qa.ibmec.br/ibmecqas.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.qa.ibmec.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://ibmecqas.onmicrosoft.com/201b732f-6414-49f9-bf61-721e90f0a7cd/user.read',
      },
    },
  },
  {
    envs: ['prd'],
    brands: ['ibmec'],
    config: {
      type: 'msal',
      dados: {
        auth: {
          clientId: '370c3901-2de5-4fdb-8bdc-0e6d4706be60',
          authority: 'https://login.ibmec.br/loginibmec.onmicrosoft.com/B2C_1_signin_sava_candidatos',
          knownAuthorities: ['login.ibmec.br'],
          redirectUri: `${window.location.protocol}//${window.location.host}`,
          postLogoutRedirectUri: `${portal}/auth-external?origin=sava`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback,
          },
        },
        scope: 'https://loginibmec.onmicrosoft.com/370c3901-2de5-4fdb-8bdc-0e6d4706be60/user.read',
      },
    },
  },
];
