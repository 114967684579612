import { LftButton, LftTypography } from '@lift/design-system-react-web/dist/components';
import { useBrandToken } from '@lift/design-system-react-web/dist/contexts';
import React from 'react';
import { ModalLateral } from '../modal-lateral/modal-lateral.component';
import { Chrome } from './icons/chrome/chrome.icon';
import { Firefox } from './icons/firefox/firefox.icon';
import S from './modal-v-libras.styles';
import { Props } from './modal-v-libras.types';

export const ModalVLibras: React.FC<Props> = ({ onClose }) => {
  const titulo = 'Conheça o VLibras';
  const pluginChrome = 'https://chrome.google.com/webstore/detail/vlibras/pgmmmoocgnompmjoogpnkmdohpelkpne?hl=pt-BR';
  const pluginFirefox = 'https://addons.mozilla.org/en-US/firefox/addon/vlibras-plugin/';

  const acessarLink = (url: string) => window.open(url, '_blank');

  const { NeutralColorDarkPure } = useBrandToken();

  return (
    <ModalLateral
      data-testid="modal-vlibras"
      id={'modal-vlibras'}
      titulo={titulo}
      visible={true}
      conteudo={
        <S.ModalVLibras>
          <S.ModalVLibrasDescricao>
            <LftTypography component="paragraph" fontWeight="bold">
              Como funciona:
            </LftTypography>
            <LftTypography component="paragraph">
              O VLibras é uma suíte de ferramentas utilizadas na tradução automática do Português para a Língua
              Brasileira de Sinais. É possível utilizar essas ferramentas tanto no computador Desktop quanto em
              smartphones e tablets.
            </LftTypography>
          </S.ModalVLibrasDescricao>

          <S.ModalVLibrasPassoAPasso>
            <LftTypography component="paragraph">Passo a passo:</LftTypography>

            <S.ModalVLibrasPassoAPassoDescricao>
              <LftTypography component="paragraph">
                Após instalar o plugin, selecione o texto que deseja ser traduzido pelo VLibras.
              </LftTypography>
              <LftTypography component="paragraph">
                Clique no texto selecionado, com o botão direito do mouse.
              </LftTypography>
              <LftTypography component="paragraph">
                E clique na opção TRADUZIR &quot;Texto selecionado&quot; PARA LIBRAS.
              </LftTypography>
            </S.ModalVLibrasPassoAPassoDescricao>
          </S.ModalVLibrasPassoAPasso>

          <S.ModalVLibrasImagens>
            <S.ImagemWrapper data-testid="modal-vlibras-imagem-extensao" tabIndex={-1} aria-hidden="true">
              <img src="/assets/images/vlibras.png" aria-hidden="true" />
            </S.ImagemWrapper>
            <S.ModalVLibrasNavegadores>
              <S.ModalVLibrasNavegadoresTitulo>
                <LftTypography component="paragraph" fontWeight="bold">
                  Para navegadores
                </LftTypography>
              </S.ModalVLibrasNavegadoresTitulo>

              <S.ModalVLibrasDivBotaoNavegador>
                <Chrome color={NeutralColorDarkPure} testId="modal-vlibras-icone-chrome" />

                <LftButton
                  label="Chrome"
                  size="lg"
                  actionLevel="primary"
                  ariaLabel="Link da extensão VLibras para o Chrome"
                  lftClick={() => acessarLink(pluginChrome)}
                  dataTestId="modal-vlibras-botao-extensao-chrome"
                >
                  Chrome
                </LftButton>
              </S.ModalVLibrasDivBotaoNavegador>
              <S.ModalVLibrasDivBotaoNavegador>
                <Firefox color={NeutralColorDarkPure} testId="modal-vlibras-icone-firefox" />
                <LftButton
                  label="Firefox"
                  size="lg"
                  actionLevel="primary"
                  ariaLabel="Link da extensão VLibras para o Firefox"
                  lftClick={() => acessarLink(pluginFirefox)}
                  dataTestId="modal-vlibras-botao-extensao-firefox"
                >
                  Firefox
                </LftButton>
              </S.ModalVLibrasDivBotaoNavegador>
            </S.ModalVLibrasNavegadores>
          </S.ModalVLibrasImagens>
        </S.ModalVLibras>
      }
      onClose={onClose}
      ariaLabelBotaoFechar={'Fechar a modal de apresentação do VLibras'}
    />
  );
};
