import RefreshCcw from '@lift/assets/dist/assets/globals/icons/refresh_ccw';
import { LftButton, LftTypography } from '@lift/design-system-react-web/dist/components';
import { useEffect } from 'react';
import S from '../screen-error.styles';

export const Fatal: React.FC<{ erro: string }> = ({ erro }) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push({
      event: 'env_error', // Evento que aciona o acompanhamento de erros
      error_details: {
        // Detalhes do erro
        code: '404', // Código do erro
        description: erro, // Descrição ou mensagem do erro (Ex.: Erro ao carregar conteúdo em Home Sava Candidato)
        is_fatal: true, // Se o erro for fatal, setar como true
      },
    });
  }, [erro]);

  return (
    <S.Container data-testid="screen-error" data-section="section_erro-carregar-conteudo">
      <LftTypography component="title" size="small">
        Tivemos problemas ao carregar <br /> o conteúdo da página
      </LftTypography>

      <LftTypography component="paragraph" size="small">
        Pedimos desculpa pelo imprevisto. <br />
        Por favor, tente atualizar a página novamente.
      </LftTypography>

      <S.ButtonContainer>
        <LftButton
          ariaLabel="Atualizar página"
          actionLevel="primary"
          dataElement="atualizar-a-pagina"
          icon={RefreshCcw}
          size="lg"
          type="button"
          lftClick={() => location.reload()}
        >
          Atualizar a página
        </LftButton>
      </S.ButtonContainer>
    </S.Container>
  );
};
