import styled from '@emotion/styled';

const MenuSanduicheContent = styled.div`
  flex: 1;
`;

const Divider = styled.hr`
  margin: 0;

  width: 100%;
  border-color: ${({ theme }) => theme.NeutralColorLightHigh};
  opacity: ${({ theme }) => theme.OpacityLevelMedium};

  align-self: center;
`;

const RodapeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const S = {
  MenuSanduicheContent,
  Divider,
  RodapeWrapper,
};

export default S;
