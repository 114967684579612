import styled from '@emotion/styled';

const Container = styled.header`
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 0 40px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.NeutralColorLightPure};
`;

const S = {
  Container,
};

export default S;
