/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

/**
 * @description Deve ser passado a referencia do elemento renderizado, que está sendo controlado. Será disparado quando o usuario
 * clicar fora do elemento de referencia.
 * @param ref É a referencia do elemento, conseguido por meio do useRef do react
 * @param handler É uma função de callback.
 */

export const useOnClickOutside = (ref: any, handler: (arg0: any) => void) => {
  useEffect(() => {
    const listener = (event: { target: any }) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
