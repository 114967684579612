import S from './header.styles';
import { HeaderProps } from './header.types';

export const Header: React.FC<HeaderProps> = ({ logo, center, aside }) => {
  return (
    <S.Container>
      {logo}
      {center}
      {aside}
    </S.Container>
  );
};
