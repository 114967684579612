import { useStore } from 'effector-react';
import { Guard } from '../../../../features/features.types';
import { $usuario } from '../../../../stores/usuario/usuario.store';

export const UsuarioMigradoGuard: Guard = ({ render, fallback }) => {
  const usuario = useStore($usuario);

  const usuarioMigrado = Boolean(usuario?.estudanteMigrado);

  // Um usuário que já foi migrado não pode mais acessar as rotas
  if (usuarioMigrado) {
    return fallback;
  }

  return render;
};
