import React from 'react';
import S from './lista-acessibilidade.styles';
import { ListaAcessibilidadeProps } from './lista-acessibilidade.types';

export const ListaAcessibilidade: React.FC<ListaAcessibilidadeProps> = ({ itens }) => {
  return (
    <S.ListaDiv id="container-lista-menu-acessibilidade">
      {itens.map((item) => (
        <div key={`menu-acessibilidade-item-${item.key}`} className="item-acessibilidade">
          {item}
        </div>
      ))}
    </S.ListaDiv>
  );
};
