import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

interface ContainerProps {
  theme?: Theme;
  active: boolean;
}

const ContainerActive = (props: ContainerProps) => {
  const bg = props.theme?.NeutralColorLightHigh;

  return css`
    .header {
      background-color: ${bg};
      border-radius: 8px;
    }

    .chevronIcon {
      transform: rotate(-180deg);
    }
  `;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  ${(props) => props.active && ContainerActive}
`;

const HeaderButton = styled.button`
  border: none;
  background: none;

  width: 100%;
  min-height: 56px;
  padding: 0 14px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ChevronIcon = styled.div`
  transition: transform 0.6s ease;
`;

const S = {
  Container,
  HeaderButton,
  Label,
  ChevronIcon,
};

export default S;
