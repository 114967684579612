import { useBrandToken } from '@lift/design-system-react-web/dist/contexts';
import { useStore } from 'effector-react';
import React, { useMemo, useState } from 'react';
import { useResponsividade } from '../../hooks/responsividade/responsividade.hook';
import { $contraste, toggleAltoContraste } from '../../stores/alto-contraste/alto-contraste.store';
import { ModalVLibras } from '../modal-v-libras';
import { DropdownWrapper } from './fragments/dropdown-wrapper/dropdown-wrapper.fragment';
import { ItemLista } from './fragments/item-lista/item-lista.fragment';
import { ListaAcessibilidade } from './fragments/lista/lista-acessibilidade.fragment';
import { LibrasIcon } from './icons/libras/libras.icon';
import { ToggleThemeIcon } from './icons/toggle-theme/toggle-theme.icon';
import { MenuAcessibilidadeProps } from './menu-acessibilidade.types';

export const MenuAcessibilidade: React.FC<MenuAcessibilidadeProps> = ({ dropdownPosition }) => {
  const [exibirModalVLibras, setExibirModalVLibras] = useState<boolean>(false);
  const [exibirDropdown, setExibirDropdown] = useState<boolean>(false);

  const { desktop } = useResponsividade();
  const { PrimaryActionColorPure } = useBrandToken();
  const altoContraste = useStore($contraste);

  const habilitarOpcaoVLibras = desktop;

  const itensLista = useMemo(() => {
    const itens = [];

    itens.push(
      <ItemLista
        data-testid="item-alto-contraste"
        key={1}
        label="Alto Contraste"
        onClick={() => {
          toggleAltoContraste(!altoContraste);
          setExibirDropdown(false);
        }}
        icon={<ToggleThemeIcon color={PrimaryActionColorPure} />}
      />,
    );

    if (habilitarOpcaoVLibras) {
      itens.push(
        <ItemLista
          data-testid="item-vlibras"
          key={2}
          label="V Libras"
          onClick={() => {
            setExibirModalVLibras(true);
            setExibirDropdown(false);
          }}
          icon={<LibrasIcon color={PrimaryActionColorPure} />}
        />,
      );
    }

    return itens;
  }, [PrimaryActionColorPure, altoContraste, habilitarOpcaoVLibras]);

  return (
    <section id="section-menu-acessibilidade" data-testid="section-menu-acessibilidade">
      <DropdownWrapper
        dropdownPosition={dropdownPosition}
        displayDropdown={exibirDropdown}
        setDisplayDropdown={setExibirDropdown}
      >
        <ListaAcessibilidade itens={itensLista} />
      </DropdownWrapper>

      {exibirModalVLibras && <ModalVLibras onClose={() => setExibirModalVLibras(false)} />}
    </section>
  );
};
