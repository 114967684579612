import React from 'react';

interface Props {
  color: string;
  testId: string;
}

export const Chrome: React.FC<Props> = ({ color, testId }) => {
  return (
    <svg
      data-testid={testId}
      aria-hidden="true"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4209 10.2298L2.69043 4.49737C5.01465 1.60674 8.50098 0.0149453 12.0654 0.000296831C14.1309 -0.0143516 16.2402 0.512992 18.1592 1.62139C20.2783 2.85186 21.8896 4.61944 22.915 6.65069L12.8906 6.12334C10.0537 5.95733 7.35352 7.55401 6.4209 10.2298ZM8.02734 12.1097C8.02734 14.3655 9.85351 16.1917 12.1094 16.1917C14.3652 16.1917 16.1914 14.3655 16.1914 12.1097C16.1914 9.85381 14.3652 8.02764 12.1094 8.02764C9.85351 8.02764 8.02734 9.84893 8.02734 12.1097ZM23.4033 7.7542L16.582 8.10577C18.4326 10.2689 18.4619 13.389 16.9043 15.7815L11.4307 24.1995C13.7012 24.3216 16.04 23.8235 18.1592 22.5931C23.4033 19.5657 25.5273 13.2181 23.4033 7.7542ZM6.52832 14.4339L1.97266 5.47393C0.727539 7.37823 0 9.66338 0 12.1097C0 18.1644 4.43359 23.179 10.2295 24.0677L13.3398 17.9739C10.5273 18.5013 7.8125 16.9583 6.52832 14.4339Z"
        fill={color}
      />
    </svg>
  );
};
