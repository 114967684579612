import axios from 'axios';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import {
  CodigosApiResponse,
  ConsultarCodigosCursosComplementaresService,
} from './consultar-codigos-cursos-complementares.types';

const {
  apiConfigs: { api },
} = ConfigsManager.getInstance();

export const consultarCodigosCursosComplementaresService: ConsultarCodigosCursosComplementaresService = async ({
  token,
  idCandidato,
}) => {
  const url = `${api}/cursos-complementares?candidato=${idCandidato}`;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  const { data } = await axios.get<CodigosApiResponse>(url, { headers });

  return data;
};
