import { css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

export const globalStyles = css`
  ${emotionNormalize}
  html,
  body {
    display: flex;
    flex: 1;
    padding: 0;
    margin: 0;
    min-height: 100%;

    #root {
      width: 100%;
    }

    [data-lift='lft-cardshape'] {
      min-width: 48px;
      min-height: 24px;
    }

    [data-lift='lft-tag'] {
      width: fit-content;
    }
  }

  .somenteLeitorDeTela {
    position: absolute;
    top: -100vh;
    width: 0;
    height: 0;
    opacity: 0;
  }
`;
