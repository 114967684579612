interface BodyProps {
  overflow: 'hidden' | 'visible';
  ariaHidden: boolean;
  tabIndex?: number;
}

export const setBodyProps = ({ overflow, ariaHidden, tabIndex }: BodyProps) => {
  const root = document.getElementById('root');
  const body = document.getElementsByTagName('body')[0];

  if (root) {
    if (tabIndex) root.tabIndex = tabIndex;
    else root.removeAttribute('tabindex');

    root.style.overflow = overflow;
    body.style.overflow = overflow;
    root.setAttribute('aria-hidden', ariaHidden.toString());
  }
};
