import { InteractionStatus } from '@azure/msal-browser';
import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { Loading } from '../../../../components/loading/loading.component';
import { Guard } from '../../../../features/features.types';
import { useAutenticacao } from '../../../../hooks/autenticacao/autenticacao.hook';
import { IdExterno } from '../../../../models/usuario.model';
import { $autenticacao } from '../../../../stores/autenticacao/autenticacao.store';
import { autenticado as onUsuarioAutenticado } from '../../../../stores/usuario/usuario.store';
import { authSearchParams } from '../../../../utils/auth-search-params/auth-search-params';

export const AuthGuard: Guard = ({ render, fallback }) => {
  const { loginHint, idExterno: searchIdExterno } = useMemo(() => authSearchParams(), []);
  const { inProgress, getContaAtiva, acquireToken, signIn, signOut } = useAutenticacao(loginHint);

  const loading = useMemo(() => <Loading ariaLabel="Carregando página" />, []);

  const idExterno: IdExterno = useMemo(
    () => ({
      inscricao: searchIdExterno.inscricao,
      candidato: searchIdExterno.candidato,
      atila: searchIdExterno.atila,
    }),
    [searchIdExterno],
  );

  const { autenticado, pendente, erro } = useStore($autenticacao);

  // Tratamento do login
  useEffect(() => {
    if (!autenticado && loginHint && InteractionStatus.None === inProgress) {
      const conta = getContaAtiva();

      if (conta) {
        acquireToken();
      } else {
        signIn();
      }
    } else if (!loginHint) {
      signOut();
    }
  }, [acquireToken, autenticado, getContaAtiva, inProgress, loginHint, signIn, signOut]);

  return useMemo(() => {
    if (pendente) {
      return loading;
    }

    if (autenticado && !pendente && !erro && (idExterno.inscricao || idExterno.candidato || idExterno.atila)) {
      onUsuarioAutenticado(idExterno);
      return render;
    }

    localStorage.removeItem('idExterno.inscricao');
    localStorage.removeItem('idExterno.candidato');
    localStorage.removeItem('idExterno.atila');

    return fallback;
  }, [pendente, autenticado, erro, idExterno, fallback, loading, render]);
};
