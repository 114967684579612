import { Contexto } from '../environment.types';

export const ibmec: Contexto[] = [
  {
    host: 'http://localhost:3002',
    portal: 'https://candidatos.dev.portal.ibmec.br/login',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    env: 'local',
    brand: 'ibmec',
  },
  {
    host: 'https://candidato.ibmec.sava.dev.yduqs.com.br',
    portal: 'https://candidatos.dev.portal.ibmec.br',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    env: 'dev',
    brand: 'ibmec',
  },
  {
    host: 'https://candidato.ibmec.sava.qld.yduqs.com.br',
    portal: 'https://candidatos.qa.portal.ibmec.br',
    savaEstudante: 'https://estudante-sava-ibmec.estaciodev.net',
    env: 'qld',
    brand: 'ibmec',
  },
  {
    host: 'https://candidato.ibmec.sava.prd.yduqs.com.br',
    portal: 'https://candidatos.portal.ibmec.br',
    savaEstudante: 'https://estudante.ibmec.br/',
    redirect: 'https://candidatos.sava.ibmec.br',
    env: 'prd',
    brand: 'ibmec',
  },
  {
    host: 'https://candidatos.sava.ibmec.br',
    portal: 'https://candidatos.portal.ibmec.br',
    savaEstudante: 'https://estudante.ibmec.br/',
    env: 'prd',
    brand: 'ibmec',
  },
];
