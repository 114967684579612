import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Global } from '@emotion/react';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { SWRConfig } from 'swr';
import App from './app/app';
import { globalStyles } from './global.styles';
import { initApm } from './infra/configs/apm/apm.configs';
import { ConfigsManager } from './infra/configs/configs.manager';
import { env } from './infra/configs/environment/environment.configs';
import { defaultAxiosErrorInterceptor } from './infra/interceptors/default-axios-error/default-axios-error.interceptor';
import reportWebVitals from './reportWebVitals';
import { load } from './stores/autenticacao/autenticacao.store';

const {
  authConfigs: { dados },
  brand,
} = ConfigsManager.getInstance();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const msalInstance = new PublicClientApplication(dados);

load();

axios.interceptors.response.use((response) => response, defaultAxiosErrorInterceptor);

initApm({
  brand,
  env,
});

root.render(
  <React.StrictMode>
    {/*Componente do @emotion para adicionar estilos de forma global na aplicação, usa como base as definições do: 'global.styles.ts' */}
    <Global styles={globalStyles} />
    <MsalProvider instance={msalInstance}>
      <SWRConfig
        value={{
          provider: () => new Map(),
        }}
      >
        <App />
      </SWRConfig>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
