import LftAvatar from '@lift/design-system-react-web/dist/components/LftAvatar';
import LftSkeleton from '@lift/design-system-react-web/dist/components/LftSkeleton';
import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import { useCallback } from 'react';
import S from './avatar-usuario.styles';
import { AvatarUsuarioProps } from './avatar-usuario.types';

/**
 * @param nomeUsuario
 * Quando não enviado nomeUsuario, o avatar fica em modo de skeleton
 */
export const AvatarUsuario: React.FC<AvatarUsuarioProps> = ({ nomeUsuario }) => {
  const Skeleton = useCallback(
    () => (
      <S.AvatarWrapper>
        <div>
          <LftSkeleton shape="circle" />
        </div>

        <LftSkeleton shape="line" />
      </S.AvatarWrapper>
    ),
    [],
  );

  return nomeUsuario ? (
    <S.AvatarWrapper>
      <div>
        <LftAvatar description={nomeUsuario} />
      </div>

      <LftTypography component="paragraph">{nomeUsuario}</LftTypography>
    </S.AvatarWrapper>
  ) : (
    <Skeleton />
  );
};
