import { IdExterno } from '../../models/usuario.model';

export const authSearchParams = () => {
  const searchParams = new URLSearchParams(document.location.search);

  // Primeiro deve-se procurar se existe um idExterno em storage
  const storedInscricao = localStorage.getItem('idExterno.inscricao');
  const storedCandidato = localStorage.getItem('idExterno.candidato');
  const storedAtila = localStorage.getItem('idExterno.atila');

  // Busca pelos parâmetros de configuração de login
  const loginHint = searchParams.get('login_hint') ?? localStorage.getItem('login_hint');
  const forceLogin = searchParams.get('force_login');
  const logout = !!searchParams.get('signout');

  // Busca o idExterno na URL
  const candidatoQuery = searchParams.get('idExterno.candidato');
  const inscricaoQuery = searchParams.get('idExterno.inscricao');
  const atilaQuery = searchParams.get('idExterno.atila');

  let candidato = storedCandidato ? +storedCandidato : NaN;
  let inscricao = storedInscricao ? +storedInscricao : NaN;

  // O dado que vem da URL é mais iportante do que o dado em storage, que pode estar desatualizado
  if (candidatoQuery || inscricaoQuery) {
    candidato = candidatoQuery ? +candidatoQuery : NaN;
    inscricao = inscricaoQuery ? +inscricaoQuery : NaN;
  }

  const atila = atilaQuery ?? storedAtila;

  // Atualização do storage com o dado mais novo
  localStorage.setItem('idExterno.candidato', isNaN(candidato) ? '' : candidato.toString());
  localStorage.setItem('idExterno.inscricao', isNaN(inscricao) ? '' : inscricao.toString());
  localStorage.setItem('idExterno.atila', atila ?? '');

  if (forceLogin) {
    sessionStorage.setItem('force_login', forceLogin);
  }

  if (loginHint) {
    localStorage.setItem('login_hint', loginHint);
  }

  const idExterno: IdExterno = {
    inscricao: isNaN(inscricao) ? null : inscricao,
    candidato: isNaN(candidato) ? null : candidato,
    atila,
  };

  return { loginHint, forceLogin, idExterno, logout };
};
