const isNumeralRomano = (text: string) => /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/i.test(text);
const isLetra = (caractere: string) => caractere.match(/[a-zA-ZÀ-ÖØ-öø-ÿĀ-žƀ-ɏɓ-ʯ]+/);

/**
 * @description
 * Função responsável por efetuar capitalize apenas da primeira letra preservando algarismos romanos
 */
export const capitalizeFormatter = (text = '') => {
  let jaOcorreuLetra = false;

  return text
    .split(' ')
    .map((palavra) => {
      if (!jaOcorreuLetra && isLetra(palavra)) {
        jaOcorreuLetra = true;
        return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
      }

      if (isNumeralRomano(palavra)) {
        return palavra.toUpperCase();
      }

      if (palavra.length <= 2) {
        return palavra.toLowerCase();
      }

      return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
    })
    .join(' ');
};
