import { InteractionStatus } from '@azure/msal-browser';
import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { Loading } from '../../../../components/loading/loading.component';
import { Guard } from '../../../../features/features.types';
import { useAutenticacao } from '../../../../hooks/autenticacao/autenticacao.hook';
import { $autenticacao } from '../../../../stores/autenticacao/autenticacao.store';
import { autenticado as onUsuarioAutenticado } from '../../../../stores/usuario/usuario.store';
import { authSearchParams } from '../../../../utils/auth-search-params/auth-search-params';

export const LoginGuard: Guard = ({ fallback, render }) => {
  const { autenticado, pendente, token } = useStore($autenticacao);
  const { loginHint, idExterno, logout } = useMemo(() => authSearchParams(), []);
  const { inProgress, getContaAtiva, acquireToken, signIn, signOut } = useAutenticacao(loginHint);

  const loading = useMemo(() => <Loading ariaLabel="Carregando página" />, []);

  // Tratamento do login
  useEffect(() => {
    if (!autenticado && loginHint && InteractionStatus.None === inProgress) {
      const conta = getContaAtiva();

      if (conta) {
        acquireToken();
      } else {
        signIn();
      }
    }
  }, [acquireToken, autenticado, getContaAtiva, inProgress, loginHint, signIn]);

  // Tratamento de logout normal
  useEffect(() => {
    if (logout) {
      signOut();
    }
  }, [logout, signOut]);

  if (pendente) {
    return loading;
  }

  if (autenticado && token && (idExterno.inscricao || idExterno.candidato || idExterno.atila)) {
    onUsuarioAutenticado(idExterno);

    return fallback;
  }

  return render;
};
