import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import { HeaderConteudoMenuSanduicheFragment } from '../header-conteudo-menu-sanduiche/header-conteudo-menu-sanduiche.fragment';
import S from './conteudo-menu-sanduiche.styles';
import { Props } from './conteudo-menu-sanduiche.types';

export const ConteudoMenuSanduicheFragment: React.FC<Props> = ({ conteudo, rodape, onClose, menuAberto }) => {
  const [exibirConteudo, setExibirConteudo] = useState<boolean>(false);
  const [fecharConteudo, setTransicaoFecharConteudo] = useState<boolean>(false);

  const modalRootContainer = document.querySelector('#modal-root');

  const focarElementoHtml = (idElemento: string) => {
    const elemento = document.getElementById(idElemento);
    elemento?.focus();
  };

  useEffect(() => {
    if (!menuAberto) {
      setTimeout(() => setExibirConteudo(false), 400);
    } else {
      setExibirConteudo(true);
      focarElementoHtml('conteudo-menu-sanduiche');
    }
  }, [menuAberto]);

  const component = (
    <>
      {exibirConteudo && (
        <ReactFocusLock>
          <S.ConteudoMenuSaduiche
            aria-label="Você está no menu"
            role="navigation"
            transicaoExibirConteudo={exibirConteudo}
            transicaoFecharConteudo={fecharConteudo}
            data-section="section_menu-principal"
            data-testid="conteudo-menu-sanduiche"
            id="conteudo-menu-sanduiche"
          >
            <HeaderConteudoMenuSanduicheFragment
              onClick={() => {
                setTransicaoFecharConteudo(true);
                setTimeout(() => onClose(), 400);
              }}
            />

            <S.ScrollMenuSaduiche>{conteudo}</S.ScrollMenuSaduiche>

            <S.Footer>{rodape}</S.Footer>
          </S.ConteudoMenuSaduiche>
        </ReactFocusLock>
      )}
    </>
  );

  if (modalRootContainer) {
    return createPortal(component, modalRootContainer);
  }

  return component;
};
