import { LftProvider } from '@lift/design-system-react-web/dist/contexts';
import { useStore } from 'effector-react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigsManager } from '../infra/configs/configs.manager';
import { $contraste } from '../stores/alto-contraste/alto-contraste.store';
import { AppRouter } from './router/app.router';

function App() {
  const { brand } = ConfigsManager.getInstance();

  const altoContraste = useStore($contraste);

  return (
    /* Inicialização do provider do LiftDS, deve-se passar uma marca existente e um template "default" ou "high-contrast" */
    /* Caso a marca enviada não exista nos arquivos do Lift, Exibirá YDUQS que é considerado o Whitelabel */

    <LftProvider brand={brand} template={altoContraste ? 'high-contrast' : 'default'}>
      <BrowserRouter basename="/">
        <AppRouter />
      </BrowserRouter>
    </LftProvider>
  );
}

export default App;
