import XIcon from '@lift/assets/dist/assets/globals/icons/x';
import { LftIconBase, LftTypography } from '@lift/design-system-react-web/dist/components';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { setBodyProps } from '../../utils/set-body-props/set-body-props';
import S from './modal-lateral.styles';
import { Props } from './modal-lateral.types';

export const ModalLateral: React.FC<Props> = ({ id, titulo, visible, conteudo, onClose, ariaLabelBotaoFechar }) => {
  const sensitive = useRef<HTMLElement>(null);
  const ariaLabelFechar = ariaLabelBotaoFechar ? ariaLabelBotaoFechar : `Fechar ${titulo}`;
  const modalRootContainer = document.querySelector('#modal-root');

  useOnClickOutside(sensitive, () => {
    onClose();
  });

  useEffect(() => {
    setBodyProps({ overflow: 'hidden', ariaHidden: true, tabIndex: -1 });
    return () => setBodyProps({ overflow: 'visible', ariaHidden: false });
  }, []);

  const componentModal = (
    <ReactFocusLock>
      <S.ModalLateralBgOpacity visible={visible} />
      <S.ModalLateralSection
        id={id}
        data-testid={id}
        visible={visible}
        ref={sensitive}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-lateral-titulo"
      >
        <S.ModalLateralHeader data-testid="modal-lateral-header">
          <div
            id="modal-lateral-titulo"
            data-testid="modal-lateral-titulo"
            role="heading"
            aria-level={1}
            tabIndex={0}
            aria-label={titulo}
          >
            <div aria-hidden="true" tabIndex={-1}>
              <LftTypography component="title" variant="h1" size="small">
                {titulo}
              </LftTypography>
            </div>
          </div>

          <S.ModalLateralButton
            role="button"
            title-access={ariaLabelFechar}
            aria-label={ariaLabelFechar}
            onClick={onClose}
            data-testid="modal-lateral-botao-fechar"
          >
            <div aria-hidden="true" tabIndex={-1} data-testid="modal-lateral-botao-fechar-icon">
              <LftIconBase icon={XIcon} />
            </div>
          </S.ModalLateralButton>
        </S.ModalLateralHeader>

        <S.ModalLateralMainContent data-testid="modal-lateral-conteudo">{conteudo}</S.ModalLateralMainContent>
      </S.ModalLateralSection>
    </ReactFocusLock>
  );

  if (modalRootContainer) {
    return createPortal(componentModal, modalRootContainer);
  }

  return componentModal;
};
