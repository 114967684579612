import LftChevronDownIcon from '@lift/assets/dist/assets/globals/icons/chevron_down';
import LftIconBase from '@lift/design-system-react-web/dist/components/LftIconBase';
import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import React, { useState } from 'react';
import { dataElementLabelFormatter } from '../../../../utils/formatters/data-element-label/data-element-label.formatter';
import S from './menu-item-accordion.styles';
import { Props } from './menu-item-accordion.types';

export const MenuItemAccordion: React.FC<Props> = (props) => {
  const isAccordion = props.estilo === 'accordion';

  const [active, setActive] = useState(false);

  const onClick = isAccordion ? () => setActive(!active) : props.onClick;

  return (
    <S.Container active={active}>
      <S.HeaderButton
        className="header"
        aria-label={props.ariaLabel}
        onClick={onClick}
        data-testid={`menu-item-accordion-button-${props.label}`}
        aria-expanded={isAccordion ? active : undefined}
        aria-controls="conteudo-accordion"
        data-element={`link_${dataElementLabelFormatter(props.label)}`}
      >
        <S.Label>
          {props.icone}

          <LftTypography component="paragraph" size="small">
            {props.label}
          </LftTypography>
        </S.Label>

        {isAccordion && (
          <S.ChevronIcon className="chevronIcon">
            <LftIconBase icon={LftChevronDownIcon} size="md" />
          </S.ChevronIcon>
        )}
      </S.HeaderButton>

      {isAccordion && active && (
        <div id="conteudo-accordion">
          {props.conteudo}

          <button
            className="somenteLeitorDeTela"
            onClick={() => setActive(false)}
            data-testid="btn-fechar-conteudo-acessibilidade"
          >
            Fechar conteúdo {props.label}
          </button>
        </div>
      )}
    </S.Container>
  );
};
