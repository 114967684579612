import styled from '@emotion/styled';

const DropdownWrapperContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &.top {
    flex-direction: column-reverse;
  }
`;

const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropdownDiv = styled.div`
  width: 220px;
  height: auto;

  padding: 10px 16px;

  border-radius: 8px;
  box-shadow: 0px 5px 20px -4px ${(props) => props.theme.NeutralColorDarkLow};

  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  right: 0;
  top: 32px;

  &.top {
    top: unset;
    bottom: 32px;
  }

  background-color: ${(props) => props.theme.NeutralColorLightPure};
  z-index: 999;
`;

const DropdownHeader = styled.div`
  width: auto;
  height: auto;
`;

const S = {
  DropdownWrapperContainer,
  IconButton,
  DropdownDiv,
  DropdownHeader,
};

export default S;
