import React from 'react';
import { Loading } from '../../components/loading/loading.component';
import { RedirectProps } from './redirect.types';

export const Redirect: React.FC<RedirectProps> = ({ to }) => {
  window.location.href = to;

  // renderiza um loading para o usuário ter feeback visual do que acontece na tela
  return <Loading ariaLabel={`Redirecionando para ${to}`} />;
};
