import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { PushPageLoaded } from './page-datalayer.hook.types';

const { brand } = ConfigsManager.getInstance();

export const usePageDatalayer = () => {
  const { pathname } = useLocation();
  const [pathnameReal, setPathnameReal] = useState<string | null>(null);

  useEffect(() => {
    if (pathname !== '/') {
      setPathnameReal(pathname);
    }
  }, [pathname]);

  const pushPageLoadedDatalayer = useCallback((pathname: string) => {
    const pushData: PushPageLoaded = {
      event: 'page_loaded',
      page_details: {
        path: pathname,
        brand,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dataLayer.push(pushData);
  }, []);

  useEffect(() => {
    if (pathnameReal) {
      pushPageLoadedDatalayer(pathnameReal);
    }
  }, [pathnameReal, pushPageLoadedDatalayer]);
};
