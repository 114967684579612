import { CursoComplementar } from '@aura/shared.cursos-complementares-frontend';

export const cursosWyden: CursoComplementar[] = [
  {
    codigo: 'wyden-padrao-1',
    titulo: 'Descomplicando a Língua Portuguesa',
    url: 'https://stecine.azureedge.net/repositorio/00212ge/07640/index.html',
  },
  {
    codigo: 'wyden-padrao-2',
    titulo: 'Raciocínio Lógico e Resolução de Problemas',
    url: 'https://stecine.azureedge.net/repositorio/00212ge/07641/index.html',
  },
  {
    codigo: 'wyden-padrao-3',
    titulo: 'Empreendedorismo e Inovação',
    url: 'https://stecine.azureedge.net/repositorio/empreendedorismo_e_inovacao/index.html',
  },
];
