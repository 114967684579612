import { init } from '@elastic/apm-rum';
import { apiConfigs } from '../api/api.configs';
import { IdentificadorAmbiente, Initter } from './apm.types';

const readEnvironment: IdentificadorAmbiente = (env) => {
  switch (env) {
    case 'dev':
      return 'development';
    case 'qld':
      return 'homolog';
    case 'prd':
      return 'production';
    case 'ci':
    case 'local':
    default:
      return null;
  }
};

export const initApm: Initter = ({ env, brand }) => {
  const { api, apm } = apiConfigs().env(env).brand(brand).get();

  const currentEnv = readEnvironment(env);

  if (currentEnv) {
    init({
      serviceName: `sava-webapp-candidatos`,
      serverUrl: apm,
      serviceVersion: brand,
      environment: currentEnv,
      distributedTracing: true,
      distributedTracingOrigins: [new URL(api).origin],
    });
  }
};
