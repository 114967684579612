import styled from '@emotion/styled';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Divider = styled.hr`
  margin: 0;

  width: 100%;
  border-color: ${({ theme }) => theme.NeutralColorLightHigh};
  opacity: ${({ theme }) => theme.OpacityLevelMedium};

  align-self: center;
`;

const S = {
  Container,
  Section,
  List,
  Divider,
};

export default S;
