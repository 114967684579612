import styled from '@emotion/styled';

export const ListaDiv = styled.div`
  width: auto;
  height: auto;

  .item-acessibilidade {
    border-bottom: 1px solid ${({ theme: { NeutralColorLightLow } }) => NeutralColorLightLow};
  }

  .item-acessibilidade:first-of-type {
    border-top: 1px solid ${({ theme: { NeutralColorLightLow } }) => NeutralColorLightLow};
  }
`;

const S = {
  ListaDiv,
};

export default S;
