import { Assert } from '@aura/core-lib';
import { AuthenticationResult, InteractionStatus, ServerError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { resultadoAutenticacaoAtualizado } from '../../stores/autenticacao/autenticacao.store';

const {
  authConfigs: {
    dados: { scope },
  },
} = ConfigsManager.getInstance();

export const useAutenticacao = (loginHint: string | null) => {
  const { accounts, inProgress, instance } = useMsal();

  const exceptionHandler = useCallback(
    async (e: ServerError) => {
      Assert.nonNullable(loginHint);

      if (e.errorCode === 'interaction_required') {
        if (sessionStorage.getItem('component_tests') === 'true') {
          return await instance.loginRedirect({ scopes: [scope], loginHint, prompt: 'login' });
        }

        await instance.logoutRedirect();
      }

      resultadoAutenticacaoAtualizado({
        autenticado: false,
        erro: e,
        pendente: false,
        token: null,
      });
    },
    [instance, loginHint],
  );

  const acquireToken = useCallback(() => {
    if (loginHint && accounts.length > 0) {
      const conta = instance.getAccountByUsername(loginHint);

      if (conta) {
        instance.setActiveAccount(conta);
        Assert.notEmpty(scope);

        if (inProgress === InteractionStatus.None) {
          return instance
            .acquireTokenSilent({
              scopes: [scope],
              account: conta,
            })
            .then((r: AuthenticationResult) => {
              resultadoAutenticacaoAtualizado({
                autenticado: true,
                erro: null,
                pendente: false,
                token: r.accessToken ?? r.idToken,
              });
            })
            .catch(exceptionHandler);
        }
      }
    }
  }, [accounts.length, exceptionHandler, inProgress, instance, loginHint]);

  const signIn = useCallback(async () => {
    Assert.notEmpty(scope);

    if (loginHint) {
      instance
        .ssoSilent({ scopes: [scope], loginHint, prompt: 'none' })
        .then(async (r: AuthenticationResult) => {
          // Validação se a conta resultante do SSO é realmente a conta correta
          // Necessário pelo comportamento de cache do MSAL
          if (r.account?.username.toLowerCase() === loginHint.toLowerCase()) {
            return await acquireToken();
          }

          await instance.logout();
        })
        .catch(exceptionHandler);
    }
  }, [loginHint, instance, exceptionHandler, acquireToken]);

  const getContaAtiva = useCallback(() => {
    if (loginHint) {
      return instance.getAccountByUsername(loginHint);
    }

    return null;
  }, [instance, loginHint]);

  const signOut = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  return { signIn, signOut, acquireToken, getContaAtiva, inProgress };
};
