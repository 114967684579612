import { useEffect, useMemo, useState } from 'react';
import { isMobile } from '../../utils/responsividade/is-mobile';
import { ResponsividadeHook } from './responsividade.types';

export const useResponsividade: ResponsividadeHook = () => {
  const [mobile, setMobile] = useState(isMobile());

  useEffect(() => {
    function handleResize() {
      setMobile(isMobile());
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setMobile]);

  const result = useMemo(
    () => ({
      mobile,
      desktop: !mobile,
    }),
    [mobile],
  );

  return result;
};
