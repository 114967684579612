import styled from '@emotion/styled';

const Cabecalho = styled.div`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0 24px 0 0;
`;

const BotaoFechar = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const S = {
  BotaoFechar,
  Cabecalho,
};

export default S;
