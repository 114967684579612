import styled from '@emotion/styled';

const ModalVLibras = styled.section`
  white-space: normal;
`;

const ModalVLibrasDescricao = styled.div`
  margin-bottom: 32px;
`;

const ModalVLibrasPassoAPasso = styled.div`
  margin-bottom: 32px;
`;

const ModalVLibrasImagens = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 55px;
  justify-content: center;
`;

const ModalVLibrasPassoAPassoDescricao = styled.div`
  [data-lift='lft-typography'] {
    display: list-item;
    list-style-position: inside;
  }
`;

const ModalVLibrasNavegadoresTitulo = styled.div`
  margin-bottom: 16px;
`;

const ModalVLibrasNavegadores = styled.div`
  padding-top: 42px;
  margin-left: 62px;
  align-items: center;
  justify-content: center;
`;

const ModalVLibrasDivBotaoNavegador = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;

  [data-lift='lft-button'] {
    width: 100%;
    margin-left: 16px;
  }

  svg {
    width: 40px;
  }

  @media only screen and (max-width: 1023px) {
    display: grid;

    [data-lift='lft-button'] {
      margin-left: 0;
    }
  }
`;

const ImagemWrapper = styled.figure`
  margin: 0 0 10px 0;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.NeutralColorDarkHigh};
  display: flex;
  border-radius: 16px;
  height: 248px;
  overflow: hidden;

  img {
    height: 249px;
  }
`;

const S = {
  ModalVLibras,
  ModalVLibrasDescricao,
  ModalVLibrasImagens,
  ModalVLibrasPassoAPasso,
  ModalVLibrasPassoAPassoDescricao,
  ModalVLibrasNavegadoresTitulo,
  ModalVLibrasNavegadores,
  ModalVLibrasDivBotaoNavegador,
  ImagemWrapper,
};

export default S;
