import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppContainer } from '../../components/app-container/app-container.component';
import { Loading } from '../../components/loading/loading.component';
import { Router } from '../../features/features.types';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { Redirect } from '../../utils/redirect/redirect';
import { AuthGuard } from './guards/auth/auth.guard';
import { LoginGuard } from './guards/login/login.guard';
import { UsuarioMigradoGuard } from './guards/usuario-migrado/usuario-migrado.guard';

const CursosComplementaresRouter = lazy(
  () => import('../../features/cursos-complementares/cursos-complementares.router'),
);
const DisciplinasRouter = lazy(() => import('../../features/disciplinas/disciplinas.router'));

const { portal } = ConfigsManager.getInstance();

export const AppRouter: Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AuthGuard render={<AppContainer />} fallback={<Navigate to={`/login${window.location.search}`} />} />}
      >
        <Route index element={<Navigate to="disciplinas" />} />

        <Route
          path="disciplinas/*"
          element={
            <Suspense fallback={<Loading ariaLabel="Carregando Página" />}>
              <DisciplinasRouter UsuarioMigradoGuard={UsuarioMigradoGuard} />
            </Suspense>
          }
        />

        <Route
          path="cursos-complementares/*"
          element={
            <Suspense fallback={<Loading ariaLabel="Carregando Página" />}>
              <CursosComplementaresRouter UsuarioMigradoGuard={UsuarioMigradoGuard} />
            </Suspense>
          }
        />
      </Route>

      <Route path="login" element={<LoginGuard render={<Redirect to={portal} />} fallback={<Navigate to="/" />} />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
