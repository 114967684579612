import styled from '@emotion/styled';

const LogoutWrapper = styled.div`
  min-width: 48px;
  cursor: pointer;
`;

const S = { LogoutWrapper };

export default S;
