import { SerializedStyles, Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

const ACTIVE_TOTAL_WIDTH = '380px';
const CONTENT_WIDTH = '320px';
const ICON_WIDTH = '48px';
const ICON_HEIGHT = '48px';

const ContentDropdown = styled.div`
  width: ${CONTENT_WIDTH};
  height: auto;

  position: absolute;
  right: 0;

  z-index: 999;
  padding: 16px 0;
  background-color: ${(props) => props.theme.NeutralColorLightPure};
  box-shadow:
    0px 0px 5px rgba(0, 0, 0, 0.05),
    0px 25px 35px rgba(0, 0, 0, 0.03);

  display: flex;
  flex-direction: column;
`;

const ShowContentBottom = css`
  border-radius: 8px 8px 0px 8px;

  .content {
    top: 48px;

    border-radius: 0px 0px 8px 8px;
  }

  .btnMenuItemIcone {
    cursor: default;
  }
`;

const ShowContentTop = css`
  border-radius: 8px 0px 8px 8px;

  .content {
    bottom: 48px;

    border-radius: 8px 8px 0px 0px;
  }

  .btnMenuItemIcone {
    cursor: default;
  }
`;

const ShowNoContent = css`
  border-radius: 8px 8px 8px 8px;
`;

const ContainerActive = (props: { theme: Theme | undefined; contentPosition: string }) => {
  const bg = props.theme?.NeutralColorLightLow;

  const positionMap: Record<string, SerializedStyles> = {
    acima: ShowContentTop,
    abaixo: ShowContentBottom,
    semConteudo: ShowNoContent,
  };

  const ContentPosition = positionMap[props.contentPosition];

  return css`
    width: ${ACTIVE_TOTAL_WIDTH};
    background-color: ${bg};
    ${ContentPosition};
    z-index: 1;
  `;
};

const Container = styled.div<{ active: boolean; contentPosition: string }>`
  position: relative;

  ${(props) => (props.active ? ContainerActive : `${ICON_WIDTH}`)}
`;

const BtnHeader = styled.button`
  background: none;
  border: none;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: calc(${ACTIVE_TOTAL_WIDTH} - ${CONTENT_WIDTH} - ${ICON_WIDTH} + 16px);
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${ICON_WIDTH};
  height: ${ICON_HEIGHT};
`;

const S = {
  ContentDropdown,
  Container,
  BtnHeader,
  Icon,
};

export default S;
