import { createEvent, createStore } from 'effector';

// Eventos
export const toggleAltoContraste = createEvent<boolean>('toggleAltoContraste');

// Stores
export const $contraste = createStore<boolean | null>(
  JSON.parse(localStorage.getItem('SAVA.definicao_contraste_acessibilidade') as string),
).on(toggleAltoContraste, (_, value) => value);

$contraste.watch((value) => {
  localStorage.setItem('SAVA.definicao_contraste_acessibilidade', `${value}`);
});
