import { Assert } from '@aura/core-lib';
import { useListaIdsCursosComplementares } from '@aura/shared.cursos-complementares-frontend';
import { createEvent } from 'effector';
import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { consultarCodigosCursosComplementaresService } from '../../services/consultar-codigos-cursos-complementares/consultar-codigos-cursos-complementares.service';
import { $autenticacao } from '../../stores/autenticacao/autenticacao.store';
import { $usuario } from '../../stores/usuario/usuario.store';
import { CodigosCursosComplementaresResult } from './codigos-cursos-complementares.types';

export const codigosCursosAtualizadosEvent = createEvent<string[]>();

const { cursosComplementaresPadrao } = ConfigsManager.getInstance();

export const useCodigosCursosComplementares = (): CodigosCursosComplementaresResult => {
  const { token } = useStore($autenticacao);

  const usuario = useStore($usuario);

  const fonteDadosCodigosCursosComplementares = useCallback((): Promise<string[]> => {
    Assert.nonNullable(usuario);
    Assert.nonNullable(token);

    return consultarCodigosCursosComplementaresService({ token, idCandidato: usuario.id });
  }, [token, usuario]);

  const {
    codigosCursos: codigosCursosUsuario,
    error,
    retentar,
  } = useListaIdsCursosComplementares({
    fonteDados: fonteDadosCodigosCursosComplementares,
    precondicao: () => !!token && !!usuario,
    onSucessoRequisicao: (data) => codigosCursosAtualizadosEvent(data),
  });

  const codigosCursosPadrao = cursosComplementaresPadrao.map((cursoPadrao) => cursoPadrao.codigo);

  const codigosCursos = Array.from(new Set([...codigosCursosUsuario, ...codigosCursosPadrao])); // Remove ids duplicados entre os codigos de curso do usuario e padrao da marca

  return { codigosCursos, pendente: false, erro: !!error && codigosCursos.length === 0, retentar };
};
