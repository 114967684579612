import XIcon from '@lift/assets/dist/assets/globals/icons/x';
import LftIconButton from '@lift/design-system-react-web/dist/components/LftIconButton';
import LftLogotipo from '@lift/design-system-react-web/dist/components/LftLogotipo';
import React, { useCallback } from 'react';
import S from './header-conteudo-menu-sanduiche.styles';
import { Props } from './header-conteudo-menu-sanduiche.types';

export const HeaderConteudoMenuSanduicheFragment: React.FC<Props> = ({ onClick }) => {
  const focarElementoHtml = (idElemento: string) => {
    const elemento = document.getElementById(idElemento);
    elemento?.focus();
  };

  const handleClick = useCallback(() => {
    focarElementoHtml('abrir-menu-sanduiche');
    onClick();
  }, [onClick]);

  return (
    <S.Cabecalho>
      <LftLogotipo alt="Logotipo da instituição de ensino" />

      <LftIconButton
        icon={XIcon}
        lftClick={handleClick}
        ariaLabel="Fechar menu"
        dataTestId="botao-fechar-menu"
        size="lg"
      />
    </S.Cabecalho>
  );
};
