import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export interface IProps {
  transicaoExibirConteudo: boolean;
  transicaoFecharConteudo: boolean;
}

const transicaoAbrir = keyframes`
from {
  transform: translate3d(100%, 0, 0);
}
to {
  transform: none;
}
`;

const transicaoFechar = keyframes`
from {
    transform: none;
}
to {
    transform: translate3d(100%, 0, 0);
}
`;

const ConteudoMenuSaduiche = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.NeutralColorLightPure};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
  width: 94%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  animation-name: ${(props) => props.transicaoExibirConteudo && transicaoAbrir};
  animation-name: ${(props) => props.transicaoFecharConteudo && transicaoFechar};
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
`;

const ScrollMenuSaduiche = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;

  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;

  box-shadow:
    inset 0px -12px 12px -12px ${({ theme }) => theme.NeutralColorLightHigh},
    inset 0px 12px 12px -12px ${({ theme }) => theme.NeutralColorLightHigh};
`;

const Footer = styled.footer`
  padding: 24px;
`;

const S = {
  ConteudoMenuSaduiche,
  ScrollMenuSaduiche,
  Footer,
};

export default S;
