import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import React from 'react';
import S from './item-lista.styles';

interface Props {
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
}

export const ItemLista: React.FC<Props> = ({ label, onClick, icon }) => {
  return (
    <S.ItemListaButton onClick={onClick} data-testid={`btn-item-lista-menu-acessibilidade-${label}`}>
      {icon}
      <LftTypography component="paragraph" size="small">
        {label}
      </LftTypography>
    </S.ItemListaButton>
  );
};
