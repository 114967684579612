import styled from '@emotion/styled';

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.NeutralColorLightPure};

  width: 100%;
  height: 100%;

  padding: 16px 8px;
`;

const Cabecalho = styled.header`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

const Conteudo = styled.div`
  display: flex;
  flex-direction: column;

  flex: 7;
`;

const Rodape = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex: 1;
`;

const S = {
  Nav,
  Cabecalho,
  Conteudo,
  Rodape,
};

export default S;
