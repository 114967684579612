import { isAxiosError } from 'axios';
import { usuarioMigrado } from '../../../stores/usuario/usuario.store';
import { ConfigsManager } from '../../configs/configs.manager';

const { isEnabled } = ConfigsManager.getInstance();

export const defaultAxiosErrorInterceptor = async (err: unknown) => {
  if (isEnabled('AUTO_RELOAD_EXPIRED_TOKEN') && isAxiosError(err) && err.response?.status === 401) {
    await new Promise((resolve) => {
      location.reload();

      // Não necessáriamente durará 10 seundos, mas é o tempo limite da promise
      // serve para seguarar a tela em estado de loading até que o reload comece
      setTimeout(resolve, 10000);
    });
  }

  if (isEnabled('FF_MIGRACAO_ESTUDANTE') && isAxiosError(err) && err.response?.status === 423) {
    usuarioMigrado();
  }

  return Promise.reject(err);
};
