import { Environment } from '../configs.types';
import { Flag } from './is-enabled.types';

const flags: Flag[] = [
  { name: 'FF_VISUALIZACAO_E_PROGRESSO', envs: { prd: true, qld: true, dev: true, local: true } },
  { name: 'FF_MIGRACAO_ESTUDANTE', envs: { prd: true, qld: true, dev: true, local: true } },
  { name: 'AUTO_RELOAD_EXPIRED_TOKEN', envs: { prd: true, qld: true, dev: true, local: true } },
  { name: 'REDIRECT_CVC_BANNER', envs: { prd: true, qld: true, dev: false, local: false } },
  { name: 'FLAG_TESTE', envs: { prd: false, qld: true, dev: true, local: true } }, // Flag para testes
  { name: 'OCULTAR_BOTAO_SAIR', envs: { prd: true, qld: true, dev: false, local: false } },
  { name: 'SAVA_360098', envs: { prd: true, qld: true, dev: true, local: false } },
];

export const isEnabled = (flagName: string, env: Environment) => {
  const localStorageFlag = localStorage.getItem(flagName);

  if (localStorageFlag) {
    return JSON.parse(localStorageFlag);
  }

  const flag = flags.find((flag) => flag.name === flagName);

  if (flag) {
    return flag.envs[env] ?? false;
  }

  return false;
};
