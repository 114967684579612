export const IbmecApiMap = [
  {
    envs: ['local', 'ci'],
    api: 'http://localhost:5001',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'ibmec',
    tempoDedupping: 1800000,
  },
  {
    envs: ['dev'],
    api: 'https://apis.candidato.ibmec.sava.dev.yduqs.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'ibmec',
    tempoDedupping: 1800000,
  },
  {
    envs: ['qld'],
    api: 'https://apis.candidato.ibmec.sava.qld.yduqs.com.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'ibmec',
    tempoDedupping: 1800000,
  },
  {
    envs: ['prd'],
    api: 'https://apis.candidatos.sava.ibmec.br/rest',
    apm: 'https://0a45548b388140afad378bed5b3c4c21.apm.eastus.azure.elastic-cloud.com:443',
    brand: 'ibmec',
    tempoDedupping: 1800000,
  },
];
