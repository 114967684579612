import LftLoading from '@lift/design-system-react-web/dist/components/LftLoading';
import React from 'react';
import S from './loading.styles';
import { LoadingProps } from './loading.types';

export const Loading: React.FC<LoadingProps> = ({ ariaLabel, height, width }) => {
  return (
    <S.Wrapper aria-label={ariaLabel} role="progressbar" aria-busy="true" width={width} height={height}>
      <LftLoading />
    </S.Wrapper>
  );
};
