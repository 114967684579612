const modalidadesCurso = {
  PRESENCIAL: 'PRESENCIAL',
  SEMIPRESENCIAL: 'SEMIPRESENCIAL',
  TOTAL_EAD: 'TOTAL_EAD',
  AO_VIVO: 'AO_VIVO',
  FLEX_ESPECIAL: 'FLEX_ESPECIAL',
} as const;

export type ModalidadeCurso = keyof typeof modalidadesCurso;

export const modalidadesFormatadas: Record<ModalidadeCurso, string> = {
  PRESENCIAL: 'Presencial',
  SEMIPRESENCIAL: 'Semipresencial',
  TOTAL_EAD: 'Total EAD',
  AO_VIVO: 'Ao Vivo',
  FLEX_ESPECIAL: 'Flex Especial',
};
