import styled from '@emotion/styled';
import { ModalLateralStyledProps } from './modal-lateral.types';

const ModalLateralBgOpacity = styled.section<ModalLateralStyledProps>`
  display: flex;
  background-color: ${(props) => props.theme.NeutralColorDarkHigh};
  opacity: 0.7;
  position: fixed;
  top: 0;
  right: 0;
  width: ${(props) => (props.visible ? '100%' : '0')};
  height: 100%;
  z-index: 99;
`;

const ModalLateralSection = styled.section<ModalLateralStyledProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.NeutralColorLightPure};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  transition: 0.5s;
  white-space: nowrap;
  width: ${(props) => (props.visible ? '50%' : '0')};
  height: 100%;
  z-index: 999;

  @media only screen and (max-width: 1023px) {
    width: ${(props) => (props.visible ? '95%' : '0')};
    z-index: 999;
  }
`;

const ModalLateralHeader = styled.header<ModalLateralStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.NeutralColorLightLow};
  padding: 32px 64px;

  @media (max-width: 1023px) {
    padding: 24px;

    > section > button {
      padding-left: 24px;
      top: 24px;
    }
  }
`;

const ModalLateralButton = styled.button<ModalLateralStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const ModalLateralMainContent = styled.section<ModalLateralStyledProps>`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
  padding: 48px 64px;

  .tituloTrabalho {
    white-space: pre-wrap;
    margin: 0 0 24px;
  }

  @media (max-width: 1023px) {
    padding: 24px 24px 0;
  }
`;

const S = {
  ModalLateralBgOpacity,
  ModalLateralSection,
  ModalLateralHeader,
  ModalLateralButton,
  ModalLateralMainContent,
};

export default S;
