import React from 'react';

interface Props {
  color: string;
}

export const ToggleThemeIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 12C0 18.3623 5.15766 23.52 11.52 23.52C17.8823 23.52 23.04 18.3623 23.04 12C23.04 5.63769 17.8823 0.47998 11.52 0.47998C5.15771 0.47998 0 5.63764 0 12ZM11.52 20.5471V3.45288C16.2444 3.45288 20.0671 7.27636 20.0671 12C20.0671 16.7243 16.2436 20.5471 11.52 20.5471Z"
        fill={color}
      />
    </svg>
  );
};
