import { TextosConfigs } from './textos.types';

export class Textos {
  private static instance: Textos;
  private textosMap = new Map<string, string>();

  private constructor(brand: string) {
    import(`./marcas/${brand}`).then((textos) => {
      this.textosMap = new Map(Object.entries(textos));
    });
  }

  public static getInstance({ brand }: TextosConfigs): Textos {
    if (!this.instance) {
      Textos.instance = new Textos(brand);
    }

    return Textos.instance;
  }

  textos(wildcard: string) {
    return this.textosMap.get(wildcard);
  }
}
