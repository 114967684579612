import { HomeItemMenu } from '../itens-menu/home-item-menu/home-item-menu.component';
import S from './sidebar.styles';

const Sidebar: React.FC = () => {
  return (
    <S.Nav data-section="section_menu-principal">
      <S.Cabecalho>
        <HomeItemMenu apresentacao="icone" />
      </S.Cabecalho>

      <S.Conteudo></S.Conteudo>

      <S.Rodape></S.Rodape>
    </S.Nav>
  );
};

export default Sidebar;
