import styled from '@emotion/styled';

const ItemListaButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const S = {
  ItemListaButton,
};

export default S;
