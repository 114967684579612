import ChevronDownIcon from '@lift/assets/dist/assets/globals/icons/chevron_down';
import { LftModalBase } from '@lift/design-system-react-web/dist/components';
import LftIconBase from '@lift/design-system-react-web/dist/components/LftIconBase';
import { useStore } from 'effector-react';
import React, { useCallback, useState } from 'react';
import { $usuario } from '../../stores/usuario/usuario.store';
import DadosUsuario from '../dados-usuario/dados-usuario.component';
import { Logout } from '../logout/logout.component';
import { AvatarUsuario } from './fragments/avatar-usuario/avatar-usuario.fragment';
import S from './menu-avatar.styles';

export const MenuAvatar: React.FC = () => {
  const usuario = useStore($usuario);

  const [menuUsuarioVisivel, setMenuUsuarioVisivel] = useState<boolean>(false);
  const toggleMenuUsuario = useCallback(() => setMenuUsuarioVisivel(!menuUsuarioVisivel), [menuUsuarioVisivel]);

  const onClose = useCallback(() => {
    setMenuUsuarioVisivel(false);
  }, []);

  return (
    <S.Container>
      <S.AvatarButton
        aria-label="Abrir menu do usuário"
        aria-expanded={menuUsuarioVisivel}
        active={menuUsuarioVisivel}
        onClick={toggleMenuUsuario}
        data-testid="btn-menu-avatar"
      >
        <AvatarUsuario nomeUsuario={usuario?.nome} />

        <LftIconBase icon={ChevronDownIcon} id="dropdown-icon" />
      </S.AvatarButton>

      <LftModalBase size="lg" visible={menuUsuarioVisivel} lftClose={onClose}>
        <S.ModalContent>
          <S.ScrollArea>
            <DadosUsuario />
          </S.ScrollArea>

          <S.Footer>
            <Logout />
          </S.Footer>
        </S.ModalContent>
      </LftModalBase>
    </S.Container>
  );
};
