import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import { useBrandToken } from '@lift/design-system-react-web/dist/contexts';
import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../../hooks/use-on-click-outside';
import { AcessibilidadeIcon } from '../../icons/acessibilidade/acessibilidade.icon';
import S from './dropdown-wapper.styles';
import { DropdownWrapperProps } from './dropdown-wraper.types';

export const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
  children,
  dropdownPosition,
  displayDropdown,
  setDisplayDropdown,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { PrimaryActionColorPure } = useBrandToken();

  useOnClickOutside(dropdownRef, () => {
    setDisplayDropdown(false);
  });

  return (
    <S.DropdownWrapperContainer
      className={dropdownPosition}
      ref={dropdownRef}
      data-testid="container-dropdown-menu-acessibilidade"
    >
      <S.IconButton
        onClick={() => setDisplayDropdown(!displayDropdown)}
        aria-label="Acessar menu de acessibilidade"
        aria-expanded={displayDropdown}
        aria-controls="dropdown-menu-acessibilidade"
        data-testid="btn-menu-acessibilidade"
      >
        <AcessibilidadeIcon color={PrimaryActionColorPure} />
      </S.IconButton>

      {displayDropdown && (
        <S.DropdownDiv
          className={dropdownPosition}
          id="dropdown-menu-acessibilidade"
          role="region"
          aria-label="Menu de acessibilidade"
          data-testid="dropdown-menu-acessibilidade"
        >
          <LftTypography component="caption">Acessibilidade</LftTypography>

          {children}
        </S.DropdownDiv>
      )}
    </S.DropdownWrapperContainer>
  );
};
