import LftHomeIcon from '@lift/assets/dist/assets/globals/icons/home';
import { LftIconBase } from '@lift/design-system-react-web/dist/components';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemMenuDefaultProps } from '../itens-menu.types';
import { MenuItemAccordion } from '../menu-item/menu-item-accordion/menu-item-accordion.component';
import { MenuItemIcone } from '../menu-item/menu-item-icone/menu-item-icone.component';

export const HomeItemMenu: React.FC<ItemMenuDefaultProps> = ({ apresentacao }) => {
  const label = 'Home';
  const ariaLabel = 'Acessar página inicial';
  const navigate = useNavigate();

  const icone = useMemo(() => <LftIconBase icon={LftHomeIcon} />, []);
  const onClick = useCallback(() => navigate('/'), [navigate]);

  const itemIcone = useMemo(
    () => <MenuItemIcone estilo="link" label={label} ariaLabel={ariaLabel} icone={icone} onClick={onClick} />,
    [icone, onClick],
  );

  const itemAccordion = useMemo(
    () => <MenuItemAccordion estilo="link" label={label} ariaLabel={ariaLabel} icone={icone} onClick={onClick} />,
    [icone, onClick],
  );

  return useMemo(() => {
    if (apresentacao === 'icone') {
      return itemIcone;
    }

    return itemAccordion;
  }, [apresentacao, itemAccordion, itemIcone]);
};
