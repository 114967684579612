import styled from '@emotion/styled';

const Wrapper = styled.div<{ width?: string; height?: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  [data-lift='lft-loading'] {
    width: ${({ width }) => width ?? '48px'};
    height: ${({ height }) => height ?? '48px'};
  }
`;

const S = {
  Wrapper,
};

export default S;
