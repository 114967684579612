import LftTypography from '@lift/design-system-react-web/dist/components/LftTypography';
import React, { useState } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { dataElementLabelFormatter } from '../../../../utils/formatters/data-element-label/data-element-label.formatter';
import S from './menu-item-icone.styles';
import { Props } from './menu-item-icone.types';

export const MenuItemIcone: React.FC<Props> = (props) => {
  const [active, setActive] = useState(false);

  const temConteudo = props.estilo === 'dropdown';

  const onClick = temConteudo ? () => setActive(true) : props.onClick;

  return (
    <S.Container
      active={active}
      contentPosition={temConteudo ? props.posicaoConteudo : 'semConteudo'}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      data-testid={`container-menu-item-icone-${props.label}`}
    >
      <S.BtnHeader
        aria-label={props.ariaLabel}
        onClick={onClick}
        className="btnMenuItemIcone"
        aria-expanded={temConteudo ? active : undefined}
        aria-controls={temConteudo ? 'conteudo-dropdown' : undefined}
        aria-haspopup={temConteudo ? 'menu' : 'false'}
        data-testid={`btn-menu-item-icone-${props.label}`}
        data-element={`link_${dataElementLabelFormatter(props.label)}`}
      >
        <S.Icon>{props.icone}</S.Icon>

        {active && (
          <LftTypography component="paragraph" size="small">
            {props.label}
          </LftTypography>
        )}
      </S.BtnHeader>

      {temConteudo && active && (
        <S.ContentDropdown id="conteudo-dropdown" className="content">
          {/* O linter acha que a prop autoFocus é a do html padrão, porém é da lib react-focus-lock */}
          <ReactFocusLock autoFocus={false} returnFocus>
            {props.conteudo}

            <button
              className="somenteLeitorDeTela"
              onClick={() => setActive(false)}
              data-testid="btn-fechar-conteudo-acessibilidade"
            >
              Fechar conteúdo {props.label}
            </button>
          </ReactFocusLock>
        </S.ContentDropdown>
      )}
    </S.Container>
  );
};
