import { Contexto } from '../environment.types';

export const wyden: Contexto[] = [
  {
    host: 'http://localhost:3003',
    portal: 'https://candidatos.dev.portal.wyden.com.br/login',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    env: 'local',
    brand: 'wyden',
  },
  {
    host: 'https://candidato.wyden.sava.dev.yduqs.com.br',
    portal: 'https://candidatos.dev.portal.wyden.com.br',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    env: 'dev',
    brand: 'wyden',
  },
  {
    host: 'https://candidato-sava-wyden.estaciodev.net',
    portal: 'https://candidatos.dev.portal.wyden.com.br',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    env: 'dev',
    brand: 'wyden',
  },
  {
    host: 'https://candidato.wyden.sava.qld.yduqs.com.br',
    portal: 'https://candidatos.qa.portal.wyden.com.br',
    savaEstudante: 'https://estudante-sava-wyden.estaciodev.net',
    env: 'qld',
    brand: 'wyden',
  },
  {
    host: 'https://candidato.wyden.sava.prd.yduqs.com.br',
    portal: 'https://candidatos.portal.wyden.com.br',
    redirect: 'https://candidatos.sava.wyden.com.br',
    savaEstudante: 'https://estudante.wyden.edu.br/',
    env: 'prd',
    brand: 'wyden',
  },
  {
    host: 'https://candidatos.sava.wyden.edu.br',
    portal: 'https://candidatos.portal.wyden.com.br',
    savaEstudante: 'https://estudante.wyden.edu.br/',
    redirect: 'https://candidatos.sava.wyden.com.br',
    env: 'prd',
    brand: 'wyden',
  },
  {
    host: 'https://candidatos.sava.wyden.com.br',
    portal: 'https://candidatos.portal.wyden.com.br',
    savaEstudante: 'https://estudante.wyden.edu.br/',
    env: 'prd',
    brand: 'wyden',
  },
];
